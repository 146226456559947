import ReactDOM from 'react-dom/client';
import Index from "./index";

import * as Sentry from "@sentry/react";

let SENTRY_DSN: string | undefined
if (import.meta.env.PROD) {
  SENTRY_DSN = "https://01dd05b5f1df56e7f6de57052f4e3e15@sentry.hep.solutions//4"
} else {
  SENTRY_DSN = undefined
}

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<Index/>);
